import { lineString } from '@turf/helpers';
import lineToPolygon from '@turf/line-to-polygon';
import cleanCoords from '@turf/clean-coords';
/*eslint-disable @typescript-eslint/ban-ts-comment*/

export function enableDoubleClickZoom(ctx): void {
  setTimeout(() => {
    // First check we've got a map and some context.
    if (!ctx.map || !ctx.map.doubleClickZoom || !ctx._ctx || !ctx._ctx.store || !ctx._ctx.store.getInitialConfigValue)
      return;
    // Now check initial state wasn't false (we leave it disabled if so)
    if (!ctx._ctx.store.getInitialConfigValue('doubleClickZoom')) return;
    ctx.map.doubleClickZoom.enable();
  }, 0);
}

export function disableDoubleClickZoom(ctx): void {
  setTimeout(() => {
    if (!ctx.map || !ctx.map.doubleClickZoom) return;
    // Always disable here, as it's necessary in some cases.
    ctx.map.doubleClickZoom.disable();
  }, 0);
}

export class PaintMode {
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onDrawFinished = function (_polygon): void {};

  onSetup = function (options): any {
    // @ts-ignore
    const line = this.newFeature({
      type: 'Feature',
      properties: {
        color: options.color
      },
      geometry: {
        type: 'LineString',
        coordinates: []
      }
    });

    // @ts-ignore
    this.clearSelectedFeatures();

    // @ts-ignore
    disableDoubleClickZoom(this);

    const currentVertexPosition = 0;

    return {
      drawing: false,
      line: line,
      currentVertexPosition
    };
  };

  onStop = function (state): void {
    // @ts-ignore
    enableDoubleClickZoom(this);
    // check to see if we've deleted this feature
    // @ts-ignore
    if (this.getFeature(state.line.id) === undefined) return;

    // @ts-ignore
    this.deleteFeature([state.line.id], { silent: true });

    // @ts-ignore
    this.changeMode('simple_select', {}, { silent: true });
  };

  stopPainting = function (state): void {
    if (state.line.coordinates.length > 1) {
      let drawPolygon = null;
      const line = cleanCoords(lineString(state.line.coordinates));
      if (line && line.geometry.coordinates.length >= 4) {
        drawPolygon = lineToPolygon(line);
      }

      // reset state before onDrawFinished not to sent line To source handling, in final source should be polygons, lineString using only for painting
      state.line.coordinates = [];
      state.currentVertexPosition = 0;
      // @ts-ignore
      this.deleteFeature([state.line.id], { silent: true });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onDrawFinished(drawPolygon);
    }

    state.line.coordinates = [];
    state.currentVertexPosition = 0;
  };

  onMouseMove = function (state, e): void {
    if (!e.originalEvent.shiftKey) {
      // instead of shift keyUp event, it doesn't triggered when map is not tapped on clicked

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.stopPainting(state);
      return;
    }

    if (e.lngLat.lng && e.lngLat.lat) {
      // @ts-ignore
      this.addFeature(state.line);
      state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
      state.currentVertexPosition++;
    }
  };

  toDisplayFeatures = function (state, geojson, display): void {
    geojson.properties.meta = 'feature';
    return display(geojson);
  };
}
