
import Mapbox from 'mapbox-gl-vue';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import mapboxgl from 'mapbox-gl';
import { geojson } from '@mapbox/tile-cover';
import { PaintMode } from '@/components/Map/paint-mode';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { BBox, Feature, FeatureCollection, featureCollection, point, Polygon, MultiPolygon } from '@turf/helpers';
import { SquareStatus } from '@/interfaces/squareStatus';
import Constants from '@/services/constants';
import { MapComponent } from '@/interfaces/mapComponent';
import { drawStyles } from '@/components/Map/draw-styles';
import bbox from '@turf/bbox';
import polygonClipping from 'polygon-clipping';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';

@Component({
  components: {
    Mapbox
  }
})
export default class Map extends Vue implements MapComponent {
  private map: mapboxgl.Map;
  private readonly rasterSourceId = 'rasterSource';
  private readonly rasterLayerId = 'rasterLayer';
  private readonly parcelsLayerId = 'parcels-layer';
  private readonly parcelsSourceId = 'parcels-source';
  private readonly squaresSourceId = 'squaresSource';
  private readonly squaresLayerId = 'squaresLayer';
  private readonly sentinelCloudsSourceId = 'sentinelCloudsSource';
  private readonly sentinelCloudsLayerId = 'sentinelCloudsLayer';
  private squares: FeatureCollection<Polygon> = null;
  private readonly COLORS = {
    WITH_CLOUDS: 'red',
    WITHOUT_CLOUDS: 'green',
    UNKNOWN: 'yellow'
  };
  isPaintNoCloudsMode = false;
  isPaintWithCloudsMode = false;
  showSentinelClouds = false;
  private draw: MapboxDraw;
  cloudProbability = 75;
  cloudTransparency = 0;
  @Prop() date: string;
  @Prop() unitId: string;
  @Prop() entityId: string;
  @Prop() entityShape: FeatureCollection<Polygon | MultiPolygon>;
  @Prop() entityShapeMultipolygon: Feature<MultiPolygon>;
  @Prop() parcelShapes: Feature[];
  @Prop() squaresStatus: SquareStatus[];
  @Prop() source: string;
  @Watch('squaresStatus')
  public onSquaresStatusChanged(): void {
    this.drawParcels();
    this.drawRasterTiles();
    this.drawSquares();
    this.drawSentinelClouds(this.showSentinelClouds);
    this.updateCloudTransparency(this.cloudTransparency);
  }

  get currentMapMode(): string {
    if (this.isPaintNoCloudsMode) {
      return 'Paint no clouds mode (keep Shift pressed to painting)';
    }
    if (this.isPaintWithCloudsMode) {
      return 'Paint with clouds mode (keep Shift pressed to painting)';
    }
    return null;
  }

  paintNoClouds(): void {
    if (this.isPaintNoCloudsMode) {
      this.draw.changeMode('simple_select');
      this.isPaintNoCloudsMode = false;
      this.map.getCanvas().style.cursor = 'unset';
      return;
    }
    this.isPaintNoCloudsMode = true;
    this.isPaintWithCloudsMode = false;
    this.map.getCanvas().style.cursor = 'crosshair';
    this.draw.changeMode('paint', { color: this.getColor(false) });
  }
  drawSentinelClouds(show: boolean): void {
    if (this.map.getLayer(this.sentinelCloudsLayerId)) {
      this.map.removeLayer(this.sentinelCloudsLayerId);
      this.map.removeSource(this.sentinelCloudsSourceId);
    }

    const script = `//VERSION=3
function evaluatePixel(samples) {
  //if (samples.dataMask == 0) return [255, 255, 255];
  	if(samples.CLP/255 < ${this.cloudProbability / 100}) return [0, 0, 0];
    return [255, 255, 255];
}
function setup() {
  return {
    input: [{
      bands: [
        "CLP",
        "dataMask"
      ]
    }],
    output: {
      bands: 3,
      sampleType:"UINT8"
    }
  }
}`;
    if (show) {
      this.map.addSource(this.sentinelCloudsSourceId, {
        type: 'raster',
        tiles: [
          `https://services.sentinel-hub.com/ogc/wms/${
            process.env.VUE_APP_SENTINEL_TOKEN_CLOUD_CURATION
          }?showLogo=false&service=WMS&request=GetMap&layers=CLOUD_MASK&styles=&format=image%2Fjpeg&transparent=false&version=1.1.1&maxcc=100&time=${
            this.date
          }&height=512&width=512&srs=EPSG%3A3857&bbox={bbox-epsg-3857}&evalscript=${btoa(script)}`
        ],
        tileSize: 512
      });
      this.map.addLayer({
        type: 'raster',
        id: this.sentinelCloudsLayerId,
        source: this.sentinelCloudsSourceId
      });
    }
  }
  paintWithClouds(): void {
    if (this.isPaintWithCloudsMode) {
      this.draw.changeMode('simple_select');
      this.isPaintWithCloudsMode = false;
      this.map.getCanvas().style.cursor = 'unset';
      return;
    }
    this.isPaintNoCloudsMode = false;
    this.isPaintWithCloudsMode = true;
    this.map.getCanvas().style.cursor = 'crosshair';
    this.draw.changeMode('paint', { color: this.getColor(true) });
  }

  private bboxIntersect(bbox1: BBox, bbox2: BBox): boolean {
    return !(bbox1[0] > bbox2[2] || bbox1[2] < bbox2[0] || bbox1[3] < bbox2[1] || bbox1[1] > bbox2[3]);
  }

  private createDrawLayer(): void {
    const paintMode = new PaintMode();
    paintMode.onDrawFinished = (polygon: Feature<Polygon>) => {
      if (polygon && this.squares) {
        let needUpdate = false;
        const polygonBbox = bbox(polygon);
        this.squares.features.forEach((feature: Feature<Polygon>) => {
          const featureBbox = bbox(feature);
          if (this.bboxIntersect(polygonBbox, featureBbox)) {
            const intersection = polygonClipping.intersection(
              polygon.geometry.coordinates as any,
              feature.geometry.coordinates as any
            );
            if (intersection && intersection.length) {
              feature.properties.color = this.getColor(this.isPaintWithCloudsMode);
              feature.properties.hasClouds = this.isPaintWithCloudsMode;
              needUpdate = true;
            }
          }
        });
        if (needUpdate) {
          this.updateGeoJsonSource(this.squaresSourceId, this.squares);
        }
      }
    };

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      styles: drawStyles,
      modes: {
        paint: paintMode,
        ...MapboxDraw.modes
      }
    });
    this.map.addControl(this.draw);
  }

  destroyed(): void {
    document.onkeyup = null;
  }

  onMapLoaded(map: mapboxgl.Map): void {
    this.map = map;
    this.createSquaresLayer();
    this.createDrawLayer();

    document.onkeyup = (e) => {
      if (e.shiftKey) {
        switch (e.code) {
          case 'KeyA':
            this.paintNoClouds();
            break;
          case 'KeyS':
            this.paintWithClouds();
            break;
        }
      }
    };
  }

  private createSquaresLayer(): void {
    this.map.addSource(this.squaresSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });
    this.map.addLayer({
      type: 'fill',
      id: this.squaresLayerId,
      source: this.squaresSourceId,
      paint: {
        'fill-color': 'rgba(0, 0, 0, 0)',
        'fill-outline-color': ['get', 'color']
      }
    });
    this.map.on('click', this.squaresLayerId, (ev) => {
      const feature = this.squares.features.find((feature: Feature<Polygon>) => {
        return booleanPointInPolygon(point([ev.lngLat.lng, ev.lngLat.lat]), feature);
      });
      if (feature) {
        let hasClouds = feature.properties.hasClouds;
        if (hasClouds) {
          hasClouds = null;
        } else if (hasClouds === false) {
          hasClouds = true;
        } else {
          hasClouds = false;
        }
        feature.properties.color = this.getColor(hasClouds);
        feature.properties.hasClouds = hasClouds;
      }
      this.updateGeoJsonSource(this.squaresSourceId, this.squares);
    });
  }

  private getColor(hasClouds: boolean): string {
    if (hasClouds) {
      return this.COLORS.WITH_CLOUDS;
    }
    if (hasClouds === false) {
      return this.COLORS.WITHOUT_CLOUDS;
    }
    return this.COLORS.UNKNOWN;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateGeoJsonSource(sourceId: string, geoJsonData: any): void {
    const source = this.map.getSource(sourceId) as mapboxgl.GeoJSONSource; // set data available only for geoJSON;
    if (source) {
      source.setData(geoJsonData);
    }
  }

  private drawParcels(): void {
    this.cleanupLayer(this.parcelsLayerId, this.parcelsSourceId);

    if (this.unitId) {
      this.map.addSource(this.parcelsSourceId, {
        type: 'vector',
        tiles: [`${process.env.VUE_APP_VECTOR_TILES_SERVER}/tile/${this.unitId}/${this.date}/{z}/{x}/{y}`]
      });

      const options = {
        id: this.parcelsLayerId,
        source: this.parcelsSourceId,
        'source-layer': 'geojsonLayer',
        type: 'line',
        paint: {
          'line-color': 'blue',
          'line-width': 2
        }
      } as any;
      if (this.source === 'sentinel' && this.unitId !== this.entityId) {
        options.filter = ['==', ['get', 'farmId'], this.entityId];
      }
      this.map.addLayer(options, this.squaresLayerId);
    }
  }
  private drawRasterTiles(): void {
    this.cleanupLayer(this.rasterLayerId, this.rasterSourceId);
    if (this.source === 'planet') {
      this.map.addSource(this.rasterSourceId, {
        type: 'raster',
        tiles: [
          `https://storage.googleapis.com/${process.env.VUE_APP_CLOUDS_FOLDER}/${this.unitId}/${this.date}/{z}/{x}/{y}.png`
        ],
        tileSize: 256
      });
    } else {
      this.map.addSource(this.rasterSourceId, {
        type: 'raster',
        tiles: [
          `https://services.sentinel-hub.com/ogc/wms/${process.env.VUE_APP_SENTINEL_TOKEN_CLOUD_CURATION}?showLogo=false&service=WMS&request=GetMap&layers=TRUE_COLOR,DATE&styles=&format=image%2Fjpeg&transparent=false&version=1.1.1&maxcc=100&time=${this.date}&height=512&width=512&srs=EPSG%3A3857&bbox={bbox-epsg-3857}`
        ],
        tileSize: 512
      });
    }
    this.map.addLayer(
      {
        type: 'raster',
        id: this.rasterLayerId,
        source: this.rasterSourceId
      },
      this.parcelsLayerId
    );
  }

  private drawSquares(): void {
    if (this.entityShapeMultipolygon) {
      const farmBox = bbox(this.entityShape);
      this.map.fitBounds(farmBox as BBox2d, { animate: false, padding: 50 });
      this.squares = geojson(this.entityShapeMultipolygon.geometry, Constants.squareLimits);
      this.squares.features.forEach((feature: Feature, index: number) => {
        feature.properties.color = this.getColor(this.squaresStatus[index].hasClouds);
        feature.properties.x = this.squaresStatus[index].x;
        feature.properties.y = this.squaresStatus[index].y;
        feature.properties.hasClouds = this.squaresStatus[index].hasClouds;
        feature.properties.zoom = Constants.squareLimits.min_zoom;
      });
      this.updateGeoJsonSource(this.squaresSourceId, this.squares);
    } else {
      this.squares = null;
      this.updateGeoJsonSource(this.squaresSourceId, featureCollection([]));
    }
  }

  private cleanupLayer(layerId: string, sourceId: string): void {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
  }

  public getProcessedSquares(): FeatureCollection<Polygon> {
    return featureCollection(
      this.squares
        ? this.squares.features.filter((feature: Feature<Polygon>) => feature.properties.hasClouds !== null)
        : []
    );
  }

  public clearMap(): void {
    if (this.map) {
      this.cleanupLayer(this.rasterLayerId, this.rasterSourceId);
      this.cleanupLayer(this.parcelsLayerId, this.parcelsSourceId);
      this.updateGeoJsonSource(this.squaresSourceId, featureCollection([]));
    }
  }

  public changeGridOpacity(opacity: number): void {
    this.map.setPaintProperty(this.squaresLayerId, 'fill-opacity', opacity);
  }
  updateCloudTransparency(value: number) {
    this.cloudTransparency = value;
    this.map.setPaintProperty(this.sentinelCloudsLayerId, 'raster-opacity', (100 - value) / 100);
  }
  updateCloudProbability(value: number) {
    this.cloudProbability = value;
    this.drawSentinelClouds(true);
    this.updateCloudTransparency(this.cloudTransparency);
  }
}
