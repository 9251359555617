import { FeatureCollection, MultiPolygon, Polygon } from '@turf/helpers';

export enum EntityType {
  FarmType,
  UnitType
}

export interface Entity {
  id: string;
  Name: string;
  Area: number;
  URLat: number;
  URLong: number;
  LLLat: number;
  LLLong: number;
  ShapeID: string;
  Type: EntityType;
  Shape?: FeatureCollection<Polygon | MultiPolygon>;
}
