import Axios from 'axios';
import { AuthorizeResponse, RefreshSessionResponse } from '@/interfaces/auth';
import { Unit } from '@/interfaces/unit';
import { DatesStatistic } from '@/interfaces/datesStatistic';
import { Feature, FeatureCollection, Polygon } from '@turf/helpers';
import { Farm } from '@/interfaces/farm';
import { Entity, EntityType } from '@/interfaces/entity';

class API {
  static _URL_PREFIX = '/api';
  static _PRODUCT_MANAGER_URL = `${process.env.VUE_APP_PRODUCT_MANAGER}${API._URL_PREFIX}`;
  static _SATELLITE_SERVICE_URL = `${process.env.VUE_APP_SATELLITE_SERVICE}`;
  static _ANALYTICS_LAUNCHER_URL = `${process.env.VUE_APP_ANALYTIC_LAUNCHER}`;

  static STORAGE_BASE_URL = 'https://storage.googleapis.com';

  static authorize(role: string): Promise<AuthorizeResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/authorize?role=${role}`, {
      withCredentials: true
    }).then((result) => result.data);
  }

  static getEntityById(entityId: string, source: string): Promise<Entity> {
    return Axios.get(`${API._SATELLITE_SERVICE_URL}/cloud-filtering/entities/${entityId}/${source}`, {
      withCredentials: true
    }).then((result) => result.data);
  }

  static getEntitiesWithData(source: string): Promise<string[]> {
    return Axios.get(`${API._SATELLITE_SERVICE_URL}/cloud-filtering/units?source=${source}`, {
      withCredentials: true
    }).then((result) => result.data);
  }

  static getStatusDates(unitId: string, source: string): Promise<DatesStatistic> {
    return Axios.get(`${API._SATELLITE_SERVICE_URL}/cloud-filtering/dates?unitID=${unitId}&source=${source}`, {
      withCredentials: true
    }).then((result) => result.data);
  }

  static getClouds(unitId: string, date: string, source): Promise<FeatureCollection<Polygon>> {
    let bucket = '';
    if (source === 'planet') {
      bucket = process.env.VUE_APP_CLOUDS_FOLDER;
    }
    if (source === 'sentinel') {
      bucket = process.env.VUE_APP_CLOUDS_FOLDER_SENTINEL;
    }

    return Axios.get(`https://storage.googleapis.com/${bucket}/${unitId}/${date}/clouds.geojson?v=${Date.now()}`)
      .then((result) => result.data)
      .catch(() => null);
  }

  static save(entityId: string, date: string, data: FeatureCollection<Polygon>, source: string): Promise<void> {
    return Axios.post(
      `${API._SATELLITE_SERVICE_URL}/cloud-filtering/save?unitID=${entityId}&date=${date}&source=${source}`,
      data,
      {
        withCredentials: true
      }
    ).then((result) => result.data);
  }

  static finalize(
    entityId: string,
    entityType: EntityType,
    date: string,
    data: FeatureCollection<Polygon>,
    source: string,
    keepVM: boolean,
    downloadPlanet: boolean,
    manualCuration: boolean,
    seasons?: string
  ): Promise<void> {
    return Axios.post(
      `${API._SATELLITE_SERVICE_URL}/cloud-filtering/finalize?unitID=${entityId}&date=${date}&source=${source}&type=${
        entityType === EntityType.UnitType ? 'unit' : 'farm'
      }&seasons=${seasons || ''}&keepVM=${keepVM}&downloadPlanet=${downloadPlanet}&manualCuration=${manualCuration}`,
      data,
      {
        withCredentials: true
      }
    ).then((result) => result.data);
  }

  static runAnalytic(unitId: string, date: string): Promise<void> {
    const body = {
      base: {
        analytics: ['sugarcontent']
      },
      date: date,
      unit_id: unitId
    };
    return Axios.post(`${API._ANALYTICS_LAUNCHER_URL}/satellite-survey`, body, {
      withCredentials: true
    }).then((result) => result.data);
  }

  static getUnit(unitId: string): Promise<Unit> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/unit?id=${unitId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }
  static getFarm(farmId: string): Promise<Farm> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/farm?id=${farmId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }
  static getFarms(unitId: string): Promise<Farm> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/farms?unit=${unitId}`, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static async getEntity(entityId: string): Promise<Entity> {
    const farm = await API.getFarm(entityId);
    if (farm) {
      farm.Type = EntityType.FarmType;
      return farm;
    }
    const unit = await API.getUnit(entityId);
    if (unit) {
      unit.Type = EntityType.UnitType;
      return unit;
    }
  }
  static getParcelShapes(unitId: string): Promise<Feature[]> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/unit-shape?unit=${unitId}`, {
      withCredentials: true
    }).then((result) => result.data.features);
  }
  static hasPublishedAnalytics(farmID: string, date: string): Promise<boolean> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/farm-summary?farm=${farmID}&from=${date}&to=${date}`, {
      withCredentials: true
    }).then((result) => result.data && result.data.length > 0);
  }

  static refreshSession(): Promise<RefreshSessionResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/refresh-session`, { withCredentials: true }).then(
      (result) => result.data
    );
  }
}

export default API;
