var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"map-container"},[_c('Mapbox',{attrs:{"access-token":"pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ","map-options":{
      container: 'map',
      style: {
        version: 8,
        sources: {},
        zoom: 19,
        layers: [],
        id: 'gamaya'
      },
      tileSize: 256,
      preserveDrawingBuffer: true,
      pitchWithRotate: false
    },"scale-control":{ show: true },"nav-control":{ show: true, position: 'bottom-left' }},on:{"map-load":_vm.onMapLoaded}}),(!!_vm.currentMapMode)?_c('div',{staticClass:"map-mode"},[_c('div',{staticClass:"map-mode-label"},[_vm._v(_vm._s(_vm.currentMapMode))])]):_vm._e(),_c('div',{staticClass:"map-overlay"},[_c('div',{staticClass:"paint-container"},[_c('div',{staticClass:"btn paint-no-clouds",on:{"click":_vm.paintNoClouds}},[_vm._v("Paint no clouds (Shift+A)")]),_c('div',{staticClass:"btn paint-with-clouds",on:{"click":_vm.paintWithClouds}},[_vm._v("Paint with clouds (Shift+S)")]),(_vm.source === 'sentinel' && _vm.date)?_c('div',{staticClass:"show-sentinel-clouds"},[_c('a-checkbox',{staticClass:"checkbox",on:{"click":function($event){return _vm.drawSentinelClouds($event.target.checked)}},model:{value:(_vm.showSentinelClouds),callback:function ($$v) {_vm.showSentinelClouds=$$v},expression:"showSentinelClouds"}},[_vm._v("Show Sentinel Clouds")]),_c('a-slider',{attrs:{"disabled":!_vm.showSentinelClouds,"min":0,"max":100},on:{"change":function($event){return _vm.updateCloudTransparency($event)}},model:{value:(_vm.cloudTransparency),callback:function ($$v) {_vm.cloudTransparency=$$v},expression:"cloudTransparency"}}),_c('span',[_vm._v("Cloud Probability")]),_c('a-slider',{attrs:{"disabled":!_vm.showSentinelClouds,"min":0,"max":100,"step":5},on:{"change":function($event){return _vm.updateCloudProbability($event)}},model:{value:(_vm.cloudProbability),callback:function ($$v) {_vm.cloudProbability=$$v},expression:"cloudProbability"}})],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }