
import { Component, Vue } from 'vue-property-decorator';
import Map from '@/components/Map/Map.vue';
import API from '@/services/api';
import { message, Modal } from 'ant-design-vue';
import { SquareStatus } from '@/interfaces/squareStatus';
import { tiles } from '@mapbox/tile-cover';
import Constants from '@/services/constants';
import { Unit } from '@/interfaces/unit';
import { Farm } from '@/interfaces/farm';
import { DatesStatistic } from '@/interfaces/datesStatistic';
import moment from 'moment';
import { DateStatus } from '@/enum/dateStatus';
import { MapComponent } from '@/interfaces/mapComponent';
import { Feature, FeatureCollection, Polygon, MultiPolygon, multiPolygon } from '@turf/helpers';
import { Entity, EntityType } from '@/interfaces/entity';
import * as Sentry from '@sentry/vue';

@Component({
  components: {
    Map
  }
})
export default class Curator extends Vue {
  private DATE_MASK = 'YYYY-MM-DD';
  private LOCAL_STORAGE_GRID_SIZE = 'gridSize';
  private defaultSize = this.getDefaultGridSize();
  public selectedDate: string = null;
  public controlDate: moment.Moment = null;
  public controlEntityId: string = null;
  public controlUnitId: string = null;
  public unitId: string = null;
  public entityType: EntityType;
  public entities: Entity[] = [];
  public entityIds: string[] = [];
  public units: Unit[] = [];
  public farms: Farm[] = [];
  public squaresStatus: SquareStatus[] = null;
  public entityShape: FeatureCollection<Polygon | MultiPolygon> = null;
  public entityShapeMultipolygon: Feature<MultiPolygon> = null;
  public parcelShapes: Feature[] = null;
  public datesStatistic: DatesStatistic = null;
  public tilesSize = null;
  public controlTilesSize = this.defaultSize;
  public controlGridOpacity = 100;
  private squareTilesForEntity = null;
  private cloudsMask: FeatureCollection<Polygon> = null;
  private selectedTab = 'sentinel';
  private controlSelectedTab = this.selectedTab;
  private analyticAlreadyRan = false;
  private originalCloudMask: {
    [key: number]: FeatureCollection<Polygon>;
  } = {};
  private seasons = [
    { label: '2023/2024 season', value: '2024' },
    { label: '2022/2023 season', value: '2023' },
    { label: '2021/2022 season', value: '2022' },
    { label: '2020/2021 season', value: '2021' },
    { label: '2019/2020 season', value: '2020' },
    { label: '2018/2019 season', value: '2019' }
  ];
  private selectedSeasons: string[] = ['2024'];
  private refreshSessionInterval = 600000; // millisecond, 10 min
  keepVm = false;
  manualCuration = true;
  downloadPlanet = false;
  $refs: {
    map: MapComponent;
  };

  get analyticDateLabel() {
    // currently show date only for nematodes (sentinel)
    const dates = Object.keys(this.datesStatistic ?? {}).sort();
    return dates[dates.length - 1];
  }

  get lastProcessingDate(): string {
    // currently show date only for nematodes (sentinel)
    const dates = Object.values(this.datesStatistic ?? {})
      .map((d) => d.lastTriggered)
      .filter(Boolean)
      .sort();
    return dates[dates.length - 1];
  }

  private getDefaultGridSize(): number {
    const size = localStorage.getItem(this.LOCAL_STORAGE_GRID_SIZE);
    return size ? parseInt(size) : 18;
  }

  async onSelectDate(date: moment.Moment): Promise<void> {
    const isAllow = await this.validateAllowChange();
    if (isAllow) {
      this.selectedDate = date.format(this.DATE_MASK);
      await this.onDateChanged();
    } else {
      this.controlDate = moment(this.selectedDate, this.DATE_MASK);
    }
  }

  async onSelectDateFromList(date: string): Promise<void> {
    const isAllow = await this.validateAllowChange();
    if (isAllow) {
      this.selectedDate = date;
      this.controlDate = moment(this.selectedDate, this.DATE_MASK);
      await this.onDateChanged();
    }
  }

  async onSizeChange(): Promise<void> {
    const isAllow = await this.validateAllowChange();
    if (isAllow) {
      this.tilesSize = this.controlTilesSize;
      Constants.squareLimits.max_zoom = this.tilesSize;
      Constants.squareLimits.min_zoom = this.tilesSize;
      this.cloudsMask = this.originalCloudMask[this.tilesSize] || null;
      this.createGrid();
      localStorage.setItem(this.LOCAL_STORAGE_GRID_SIZE, this.tilesSize);
      this.defaultSize = this.tilesSize;
    } else {
      this.controlTilesSize = this.tilesSize;
    }
  }

  onGridOpacityChange(): void {
    this.$refs.map.changeGridOpacity(this.controlGridOpacity / 100);
  }

  private createGrid(): void {
    this.squareTilesForEntity = this.entityShapeMultipolygon
      ? tiles(this.entityShapeMultipolygon.geometry, Constants.squareLimits)
      : [];
    this.createSquaresStatus();
  }

  private validateAllowChange(): Promise<boolean> {
    if (this.hasChanges()) {
      return new Promise((resolve) => {
        Modal.confirm({
          title: 'Are you sure?',
          content: 'You have not saved changes',
          onOk() {
            resolve(true);
          },
          onCancel() {
            resolve(false);
          }
        });
      });
    }
    return Promise.resolve(true);
  }

  getCurrentStyle(current: moment.Moment): any {
    const style = {} as any;
    const date = current.format(this.DATE_MASK);
    if (this.datesStatistic[date]) {
      style.borderRadius = '50%';
      if (this.datesStatistic[date].status === DateStatus.TODO) {
        style.border = '1px solid red';
      }
      if (this.datesStatistic[date].status === DateStatus.READY) {
        style.border = '1px solid green';
      }
      if (this.datesStatistic[date].status === DateStatus.PROGRESS) {
        style.border = '1px solid orange';
      }
    }
    return style;
  }

  isRunAnalyticDisabled(): boolean {
    if (this.selectedTab === 'planet') return !this.selectedDate || !!this.datesStatistic[this.selectedDate];
    if (this.selectedTab === 'sentinel' && this.entityType === EntityType.FarmType)
      return !this.selectedSeasons || this.selectedSeasons.length === 0;
    else if (this.entityType === EntityType.UnitType) return true;
    else return false;
  }

  removeDuplicates(value, index, self): boolean {
    return self.indexOf(value) === index;
  }

  filterOption(input, option) {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  async mounted(): Promise<void> {
    try {
      const response = await API.authorize('admin');
      const userInfo = response.UserInfo;
      this.$store.dispatch('setUserInfo', userInfo);
      Sentry.setUser({ email: userInfo.Email, username: userInfo.FirstName + ' ' + userInfo.LastName });

      await this.loadEntities();
    } catch (error) {
      if (error.response.status === 403) {
        window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
      }
    }

    setInterval(() => {
      API.refreshSession();
    }, this.refreshSessionInterval);
  }

  async loadEntities(): Promise<void> {
    await this.$store.dispatch('showGlobalLoader', true);
    this.entityIds = [];
    this.entityIds = await API.getEntitiesWithData(this.selectedTab);
    if (this.selectedTab === 'planet') {
      this.entities = (await Promise.all(this.entityIds.map(API.getUnit))).filter((unit: Unit) => !!unit);
    } else if (this.selectedTab === 'sentinel') {
      let unitIds = [];
      this.entities = (await Promise.all(this.entityIds.map(API.getEntity))).filter((entity: Entity) => !!entity);
      this.entities.map((entity) => unitIds.push((entity as Farm).UnitID));
      const filteredUnitIds = unitIds.filter(this.removeDuplicates);
      this.units = (await Promise.all(filteredUnitIds.map(API.getUnit))).filter((unit: Unit) => !!unit);
    }

    if (this.entities && this.entities.length) {
      this.controlEntityId = this.entities[0].id;
      await this.onEntityChanged(this.controlEntityId);
    }
    await this.$store.dispatch('showGlobalLoader', false);
  }

  async onUnitChanged(unitId: string): Promise<void> {
    let farmIds = [];
    this.farms = await API.getFarms(unitId).then((farms: Farm) => Object.assign(farms));
    this.farms.map((farm) => {
      if (this.entityIds.includes(farm.id)) farmIds.push(farm.id);
    });
    this.entities = (await Promise.all(farmIds.map(API.getEntity))).filter((entity: Entity) => !!entity);
    this.controlEntityId = this.farms[0].id;
    this.controlUnitId = unitId;
    await this.onEntityChanged(this.controlEntityId);
  }

  async onEntityChanged(entityId: string): Promise<void> {
    this.originalCloudMask = {};
    this.selectedDate = null;
    const isAllow = await this.validateAllowChange();
    if (isAllow) {
      if (this.selectedTab === 'planet') {
        this.unitId = entityId;
      } else {
        const entity = this.entities.filter((x) => x.id === entityId)[0] as Entity;
        this.entityType = entity.Type;
        this.unitId = entity.id;
        if (this.entityType == EntityType.FarmType) {
          this.unitId = (entity as Farm).UnitID;
        }
      }
      const entity = this.entities.find((entity: Entity) => entity.id === entityId);
      if (entity) {
        await this.$store.dispatch('showGlobalLoader', true);
        this.$refs.map.clearMap();

        this.entityShape = entity.Shape;
        this.parcelShapes = await API.getParcelShapes(this.unitId);
        if (entity.Type == EntityType.FarmType) {
          this.parcelShapes = this.parcelShapes.filter((x: Feature) => x.properties['Farm'] === entity.Name);
        }

        // convert parcel shapes into a multipolygon to get slippy tiles properly
        let coords = [];
        this.parcelShapes.forEach((shape) => {
          const geometry: any = shape.geometry;
          if (geometry.type === 'Polygon') coords.push(geometry.coordinates);
          else if (geometry.type === 'MultiPolygon') coords.push(geometry.coordinates[0]);
        });
        this.entityShapeMultipolygon = multiPolygon(coords);
        this.squareTilesForEntity = this.entityShapeMultipolygon
          ? tiles(this.entityShapeMultipolygon.geometry, Constants.squareLimits)
          : [];

        this.datesStatistic = await API.getStatusDates(entity.id, this.selectedTab);

        Object.keys(this.datesStatistic).forEach((x) => {
          if (x.length < 10) {
            delete this.datesStatistic[x];
          }
        });

        await this.$store.dispatch('showGlobalLoader', false);
      }
    } else {
      this.controlEntityId = entityId;
    }

    if (this.selectedTab === 'planet') {
      this.analyticAlreadyRan = false;
      return;
    }
    const sortedDates = Object.keys(this.datesStatistic)
      .filter((x) => !!x)
      .sort();
    if (sortedDates.length === 0) {
      this.analyticAlreadyRan = false;
      return;
    }
    this.analyticAlreadyRan = await API.hasPublishedAnalytics(
      this.controlEntityId,
      sortedDates[sortedDates.length - 1]
    );
  }

  async onDateChanged(): Promise<void> {
    await this.$store.dispatch('showGlobalLoader', true);
    this.cloudsMask = await API.getClouds(this.controlEntityId, this.selectedDate, this.selectedTab);
    this.originalCloudMask = {};
    this.tilesSize =
      (this.cloudsMask &&
        this.cloudsMask.features &&
        this.cloudsMask.features.length &&
        this.cloudsMask.features[0].properties.zoom) ||
      this.defaultSize;

    this.originalCloudMask[this.tilesSize] = this.cloudsMask;
    this.controlTilesSize = this.tilesSize;
    Constants.squareLimits.max_zoom = this.tilesSize;
    Constants.squareLimits.min_zoom = this.tilesSize;
    this.createGrid();

    await this.$store.dispatch('showGlobalLoader', false);
  }

  private createSquaresStatus(): void {
    const mask = {};
    if (this.cloudsMask && this.cloudsMask.features && this.cloudsMask.features.length) {
      this.cloudsMask.features.forEach((f: Feature<Polygon>) => {
        mask[`${f.properties.x}_${f.properties.y}`] = f.properties.hasClouds;
      });
    }
    this.squaresStatus = this.squareTilesForEntity.map(([x, y]) => {
      const key = `${x}_${y}`;
      const hasClouds = mask[key] !== undefined ? mask[key] : null;
      return {
        x,
        y,
        hasClouds
      } as SquareStatus;
    });
  }

  async save(): Promise<void> {
    await this.$store.dispatch('showGlobalLoader', true);
    const squares = this.$refs.map.getProcessedSquares();
    try {
      await API.save(this.controlEntityId, this.selectedDate, squares, this.selectedTab);
      message.success('Results has been saved successfully!');
    } catch (err) {
      message.error('Something went wrong: ' + err, 5);
    }
    this.datesStatistic[this.selectedDate].status =
      this.selectedTab == 'planet' ? DateStatus.PROGRESS : DateStatus.READY;
    this.cloudsMask = squares;
    this.originalCloudMask[this.tilesSize] = this.cloudsMask;
    await this.$store.dispatch('showGlobalLoader', false);
  }

  async finalize(): Promise<void> {
    await this.$store.dispatch('showGlobalLoader', true);
    const squares = this.$refs.map.getProcessedSquares();
    try {
      await API.finalize(
        this.controlEntityId,
        this.entityType,
        this.selectedDate,
        squares,
        this.selectedTab,
        this.keepVm,
        this.downloadPlanet,
        this.manualCuration
      );
      message.success('Curation of the clouds has been finalized successfully!');
    } catch (err) {
      message.error('Something went wrong: ' + err, 5);
    }
    this.datesStatistic[this.selectedDate].status = DateStatus.READY;
    this.cloudsMask = squares;
    this.originalCloudMask[this.tilesSize] = this.cloudsMask;
    await this.$store.dispatch('showGlobalLoader', false);
  }

  async runAnalytic(): Promise<void> {
    await this.$store.dispatch('showGlobalLoader', true);
    if (this.selectedTab === 'planet') {
      try {
        await API.runAnalytic(this.controlEntityId, this.selectedDate);
        message.success('Analytic has been started successfully!');
      } catch (err) {
        message.error('Something went wrong: ' + err, 5);
      }
    } else {
      try {
        const squares = this.$refs.map.getProcessedSquares();
        const dates = Object.keys(this.datesStatistic ?? {})
          .filter((x) => x.length === 10)
          .sort();
        await API.finalize(
          this.controlEntityId,
          this.entityType,
          dates[dates.length - 1],
          squares,
          this.selectedTab,
          this.keepVm,
          this.downloadPlanet,
          this.manualCuration,
          this.selectedSeasons.join(',')
        );
        message.success('Analytic has been started successfully!');
      } catch (err) {
        message.error('Something went wrong: ' + err, 5);
      }
    }
    await this.$store.dispatch('showGlobalLoader', false);
  }

  hasChanges(): boolean {
    const squares = this.$refs.map
      .getProcessedSquares()
      .features.map((f: Feature<Polygon>) => [f.properties.x, f.properties.y, f.properties.hasClouds].join('_'));
    const clouds = this.cloudsMask
      ? this.cloudsMask.features.map((f: Feature<Polygon>) =>
          [f.properties.x, f.properties.y, f.properties.hasClouds].join('_')
        )
      : [];
    return JSON.stringify(squares.sort()) !== JSON.stringify(clouds.sort());
  }

  async onTabChanged(): Promise<void> {
    const isAllow = await this.validateAllowChange();
    if (isAllow) {
      this.cloudsMask = this.$refs.map.getProcessedSquares();
      this.selectedTab = this.controlSelectedTab;
      this.entities = null;

      await this.loadEntities();
      if (this.selectedTab === 'sentinel' && this.controlUnitId) {
        await this.onUnitChanged(this.controlUnitId);
      }
    } else {
      this.controlSelectedTab = this.selectedTab;
    }
  }
}
